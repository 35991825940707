import { useEffect, useState, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetCreditApplicationById } from '@src/api/credit-api'
import { scrollToTop } from '@src/services/utils'
import useRequiredDocumentsWithStatus from '@src/data/creditapp-hooks'
import { canPollCreditApp, computeHighLevelPage, isServerAnalysingCredit } from './credit-hooks'
import HardHitUnapprovedResultPage from './HardHitUnapprovedResultPage'
import Loader from '../../components/Loader'
import PrequalificationResultPage from './PrequalResultPage'
import HardHitApprovedResultPage from './HardHitApprovedResultPage'
import CompletedAppPage from './CompletedApp/CompletedAppPage'
import ClosedApplicationTile from './components/ClosedApplicationTile'
import InitialApprobationForm from '../InitialApprobationPage/InitialApprobationForm'
import AgentContactPage from '../CancelApplication/AgentContactPage'
import VouchedPreparePage from '../Vouched/VouchedPreparePage'

const RouteToStatePage = () => {
  const allParams = useParams()
  const id = allParams.id as string
  const [shouldPoll, setShouldPoll] = useState(false)
  const [creditApp, isFetchingCreditApp] = useGetCreditApplicationById(id, shouldPoll) // if true, poll every 5 secs
  const requiredDocuments = useRequiredDocumentsWithStatus(creditApp?.requiredDocuments, creditApp?.documents)

  const highLevelPage = computeHighLevelPage(creditApp, isFetchingCreditApp)

  useEffect(() => {
    if (creditApp) {
      const should = isServerAnalysingCredit(creditApp) && canPollCreditApp(creditApp)
      setShouldPoll(should)
    }
  }, [creditApp])

  useLayoutEffect(scrollToTop, [])

  if (isFetchingCreditApp || highLevelPage === 'loading' || !creditApp) {
    return <Loader />
  }

  return (
    <>
      {highLevelPage === 'cancelling' && <AgentContactPage />}
      {highLevelPage === 'vouched' && <VouchedPreparePage creditAppId={id} />}
      {highLevelPage === 'prequal-result' && <PrequalificationResultPage creditApp={creditApp} />}
      {highLevelPage === 'hardhit-result' && <HardHitUnapprovedResultPage creditApp={creditApp} />}
      {highLevelPage === 'first-approval' && <InitialApprobationForm creditApp={creditApp} />}
      {highLevelPage === 'approved' && (
        <HardHitApprovedResultPage creditApp={creditApp} requiredDocuments={requiredDocuments} />
      )}
      {highLevelPage === 'funding' && <CompletedAppPage />}
      {highLevelPage === 'closed' && <ClosedApplicationTile creditApp={creditApp} />}
    </>
  )
}

export default RouteToStatePage
