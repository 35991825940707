import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddressDetailDto, SelectedAddress, useAddressDetail } from '@src/api/address-api'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { reportErrorToConsole } from '@src/services/error-logger'
import { formatAddress } from '@src/services/Formatter'
import { range } from 'lodash-es'
import ScrollableAlert from '@src/components/ScrollableAlert'
import { Link } from 'react-router-dom'
import { ProvinceConfigs } from '@src/api/config-api'
import {
  COVERED_PROVINCE,
  EHomeFeeType,
  EHomeFeeTypeList,
  EMAIL,
  EProvince,
  EProvinceList,
  PHONE_NUMBER,
  PHONE_NUMBER_LINK,
} from '../../../types'
import { HomeInformationInput, HomeInformationInputSchema } from '../../../types/CreditApplicationSchema'
import { FORM_ID } from './StepperFormFooter'
import AddressAutoCompleteInput from './AddressAutoCompleteInput'

type Props = {
  applicantData: HomeInformationInput
  onApplicantUpdated: (data: HomeInformationInput) => void
  applicantName: string
  forceProvince?: EProvince
  nextStepAfter: (currentStep: string) => void
  currentStep: string
  setDisableNext: (disable: boolean) => void
  provinceConfigs: ProvinceConfigs
}

const AddressForm = ({
  applicantData,
  onApplicantUpdated,
  applicantName,
  forceProvince,
  nextStepAfter,
  currentStep,
  setDisableNext,
  provinceConfigs,
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage: lang },
  } = useTranslation()
  const [showAlert, setShowAlert] = useState(false)
  const [isEqualError, setIsEqualError] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState<SelectedAddress | null>(null)
  const [showAutoCompleteAddressInput, setShowAutoCompleteAddressInput] = useState(true)
  const [addressDetail] = useAddressDetail()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm<HomeInformationInput>({
    mode: 'onBlur',
    resolver: yupResolver(HomeInformationInputSchema(provinceConfigs)),
    defaultValues: applicantData,
  })

  const onSubmit: SubmitHandler<HomeInformationInput> = (data) => {
    onApplicantUpdated(data)
    nextStepAfter(currentStep)
  }

  const homeFeeTypeId = watch('spending.homeFeeTypeId')
  const province = watch('currentAddress.stateIso')
  const totalMonthlyHomePayment = watch('spending.totalMonthlyHomePayment')
  const civicNumber = watch('currentAddress.civicNumber')
  const apartmentNumber = watch('currentAddress.apartment')

  const uncoveredProvince = useMemo(() => {
    return Boolean(province) && !COVERED_PROVINCE.has(province as EProvince)
  }, [province])

  React.useEffect(() => {
    setDisableNext(uncoveredProvince)
  }, [uncoveredProvince, setDisableNext])

  const equalNumbers = useMemo(() => {
    return (
      civicNumber === apartmentNumber &&
      civicNumber !== '' &&
      apartmentNumber !== '' &&
      civicNumber !== undefined &&
      apartmentNumber !== undefined
    )
  }, [civicNumber, apartmentNumber])

  React.useEffect(() => {
    setDisableNext(equalNumbers)
    setShowAlert(true)
    setIsEqualError(equalNumbers)
  }, [equalNumbers, setDisableNext])

  const handleClearFields = () => {
    setShowAlert(false)
  }

  const onChangeAddress = async (address: SelectedAddress) => {
    if (!address) return
    if (!executeRecaptcha) {
      reportErrorToConsole('ReCAPTCHA non disponible.')
      return
    }
    const recaptchaToken = await executeRecaptcha('submit_addressDetail')
    const dto: AddressDetailDto = {
      placeId: address.placeId,
      recaptchaToken,
      userLanguageCode: lang as string,
    }

    const ret = await addressDetail(dto)

    const currentAddress = {
      civicNumber: ret.address.civicNumber,
      street: ret.address.street,
      apartment: ret.address.apartment ?? '',
      postalCode: ret.address.postalCode,
      city: ret.address.city,
      stateIso: ret.address.stateIso,
      country: ret.address.country,
    }

    Object.entries(currentAddress).forEach(([key, value]) => {
      setValue(`currentAddress.${key as keyof typeof currentAddress}`, value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    })
  }

  const hasAddressAutoCompleteInputError = () => {
    if (!errors.currentAddress) return false

    const fieldsToCheck: Array<keyof typeof errors.currentAddress> = [
      'civicNumber',
      'street',
      'postalCode',
      'city',
      'stateIso',
    ]

    return fieldsToCheck.some((field) => errors.currentAddress?.[field])
  }

  return (
    <section className="step-content">
      <h3 className="form-question">{t('address.provideAddress', { firstName: applicantName })}</h3>
      <ScrollableAlert
        type="warn"
        showAlert={equalNumbers && showAlert}
        message={t('common.sameCivicAndAppartmentNumber')}
        buttons={[
          {
            id: 'btn-dismiss',
            label: t('common.yes'),
            onClick: () => {
              setShowAlert(false)
              setDisableNext(false)
              setIsEqualError(false)
            },
          },
          { id: 'btn-correct', label: t('common.modify'), onClick: () => handleClearFields() },
        ]}
      />
      <ScrollableAlert
        showAlert={!!(errors?.birthDate && errors?.birthDate.type !== 'required' && errors?.birthDate.message)}
        type="warn"
        message={errors.birthDate?.message || ''}
      />
      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <ScrollableAlert
          type="warn"
          showAlert={uncoveredProvince}
          message={
            <>
              <h4>{t('common.uncoveredProvincesTitle')}</h4>
              <Trans
                i18nKey="common.uncoveredProvincesDescription"
                values={{
                  phoneNumber: PHONE_NUMBER,
                  email: EMAIL,
                }}
                components={{
                  phone: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap' }} />,
                  email: <Link to={`mailto:${EMAIL}`} style={{ textWrap: 'nowrap' }} />,
                }}
              />
              <br />
              <Trans
                i18nKey="common.contactUs"
                values={{
                  phoneNumber: PHONE_NUMBER,
                  email: EMAIL,
                }}
                components={{
                  phone: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap' }} />,
                  email: <Link to={`mailto:${EMAIL}`} style={{ textWrap: 'nowrap' }} />,
                }}
              />
            </>
          }
        />

        {showAutoCompleteAddressInput ? (
          <>
            <ScrollableAlert
              type="warn"
              showAlert={!!errors.currentAddress?.country || hasAddressAutoCompleteInputError()}
              message={
                errors.currentAddress?.country
                  ? t('addressAutoComplete.canadaAddress')
                  : t('addressAutoComplete.refineSearch')
              }
            />
            <AddressAutoCompleteInput
              setShowAutoCompleteAddressInput={setShowAutoCompleteAddressInput}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              onChangeAddress={onChangeAddress}
              fullAddressDisplay={
                applicantData.currentAddress.civicNumber ? formatAddress(applicantData.currentAddress) : ''
              }
            />
          </>
        ) : (
          <>
            <div
              className={`control-group mid ${errors.currentAddress?.apartment ? 'error' : ''} ${
                isEqualError ? 'error' : ''
              }`}
            >
              <label htmlFor="civic_number">{t('address.civicNumber')}</label>
              <input
                type="number"
                inputMode="decimal"
                id="civicNumber"
                {...register('currentAddress.civicNumber')}
                maxLength={10}
              />
            </div>

            <div className={`control-group mid  ${errors.currentAddress?.street && 'error'}`}>
              <label htmlFor="street_name">{t('address.streetName')}</label>
              <input type="text" id="street" {...register('currentAddress.street')} maxLength={100} />
            </div>

            <div className={`control-group mid  ${errors.currentAddress?.city && 'error'}`}>
              <label htmlFor="city">{t('address.city')}</label>
              <input type="text" id="city" {...register('currentAddress.city')} maxLength={50} />
            </div>

            <div className={`control-group mid  ${errors.currentAddress?.postalCode && 'error'}`}>
              <label htmlFor="postal_code">{t('address.postalCode')}</label>
              <input type="text" id="postalCode" {...register('currentAddress.postalCode')} maxLength={7} />
            </div>

            <div className="control-group mid">
              <label htmlFor="select">{t('address.province')}</label>
              <div className={`dropdown-wrap ${errors.currentAddress?.stateIso && 'error'}`}>
                <select id="stateIso" {...register('currentAddress.stateIso')} defaultValue="">
                  {!forceProvince && (
                    <>
                      <option value="" disabled>
                        {' '}
                      </option>
                      {EProvinceList.map((value) => (
                        <option value={value} key={value}>
                          {t(`enum.eProvince.${value}`)}
                        </option>
                      ))}
                    </>
                  )}
                  {forceProvince && <option value={forceProvince}>{t(`enum.eProvince.${forceProvince}`)}</option>}
                </select>
              </div>
            </div>
          </>
        )}

        <div
          className={`control-group mid ${errors.currentAddress?.apartment ? 'error' : ''} ${
            isEqualError ? 'error' : ''
          }`}
        >
          <label htmlFor="apartment_number">{t('address.apartmentNumber')}</label>
          <input type="text" id="apartment" {...register('currentAddress.apartment')} maxLength={50} />
        </div>

        <div className="control-group" style={{ paddingBottom: 0 }}>
          <label htmlFor="year" style={{ marginBottom: 0 }}>
            {t('livingAtAddressSince.howLong')}
            <span className="help-tip">
              <p>{t('livingAtAddressSince.details')}</p>
            </span>
          </label>
        </div>

        <div className="control-group">
          <div className={`dropdown-wrap ${errors.currentAddress?.months && 'error'}`}>
            <select id="months" {...register('currentAddress.months')} defaultValue={0}>
              <option value={0}>{t('common.years')}</option>
              <option value={11}>{t('common.lessThan1year')}</option>
              {range(1, 12).map((year) => (
                <option value={year * 12} key={year}>
                  {t('common.yearsLabel', { count: year })}
                </option>
              ))}
              <option value={12}>
                {t('common.moreThan')} {t('common.yearsLabel', { count: 12 })}
              </option>
            </select>
          </div>
        </div>

        <div className="control-group" style={{ paddingBottom: 0 }}>
          <label htmlFor="housing_status" style={{ marginBottom: 0 }}>
            {t('housing.currentStatus')}
            <span className="help-tip">
              <p>{t('livingAtAddressSince.details')}</p>
            </span>
          </label>
        </div>

        <div className="control-group mid">
          <label htmlFor="housing_status">{t('housing.homeFeeType')}</label>
          <div className={`dropdown-wrap ${errors.spending?.homeFeeTypeId && 'error'}`}>
            <select {...register('spending.homeFeeTypeId')} id="homeFeeTypeId">
              {EHomeFeeTypeList.map((item) => (
                <option key={item} value={item}>
                  {t(`enum.eHomeFeeType.${item}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
        {homeFeeTypeId !== EHomeFeeType.Own && homeFeeTypeId !== EHomeFeeType.WithParents && (
          <div className={`control-group mid  ${errors.spending?.totalMonthlyHomePayment && 'error'}`}>
            <label htmlFor="mortgage_amount">
              {t('housing.totalMonthlyPayment', { homeFeeType: t(`housing.${homeFeeTypeId}`) })}
            </label>
            <div className={`number-wrap ${lang === 'en' ? 'startwith' : 'endwith'}`}>
              <input
                type="number"
                inputMode="decimal"
                id="totalMonthlyHomePayment"
                {...register('spending.totalMonthlyHomePayment')}
              />
            </div>
          </div>
        )}
        {(totalMonthlyHomePayment > 0 || homeFeeTypeId === EHomeFeeType.WithParents) && (
          <div className={`control-group  ${errors.spending?.housingMonthly && 'error'}`}>
            <label htmlFor="mortgage_payment">
              {t('housing.contributedMonthlyPayment', {
                homeFeeType: t(`housing.${homeFeeTypeId}`),
              })}
            </label>
            <div className={`number-wrap ${lang === 'en' ? 'startwith' : 'endwith'}`}>
              <input
                type="number"
                inputMode="decimal"
                id="monthlyHomePayment"
                {...register('spending.housingMonthly')}
              />
            </div>
          </div>
        )}
      </form>
    </section>
  )
}

export default AddressForm
