import React, { useRef, useEffect, CSSProperties, ReactNode } from 'react'
import { Button } from '@src/types/constants'
import Alert from './Alert'

interface ScrollableAlertProps {
  showAlert: boolean
  message: ReactNode
  type: 'warn' | 'success'
  style?: CSSProperties
  buttons?: Button[] | undefined
  linkType?: string
  linkText?: string
  link?: string
}

const ScrollableAlert: React.FC<ScrollableAlertProps> = ({
  showAlert,
  message,
  type,
  buttons,
  style,
  linkText,
  linkType,
  link,
}) => {
  const alertRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (showAlert && alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [showAlert])

  return showAlert ? (
    <div ref={alertRef}>
      <Alert
        type={type}
        message={message}
        buttons={buttons}
        style={style}
        linkText={linkText}
        link={link}
        linkType={linkType}
      />
    </div>
  ) : null
}

export default ScrollableAlert
