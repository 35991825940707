import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { applicantFullName } from '@src/data/creditapp-selectors'
import { EMAIL, PHONE_NUMBER, PHONE_NUMBER_LINK } from '@src/types'
import { ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const StatusIcon = ({ message }: { message: string | ReactNode }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '10rem' }}>
      <div className="stepStatus completed">
        <i className="fas fa-check" />
      </div>
      <div className="text-center" style={{ marginTop: '0.5rem', fontSize: '0.9em' }}>
        {message}
      </div>
    </div>
  )
}

type Props = {
  creditApp: FilteredCreditApplication
}

const NoMoreActionMessage = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  return (
    <main className="form-section">
      <div
        className="container container-centered text-center"
        style={{ flexDirection: 'column', alignItems: 'center', padding: 0 }}
      >
        <div className="limited-width-container" style={{ maxWidth: '60rem' }}>
          <h1 style={{ marginBottom: '0.5rem' }}>{t('common.appNumber', { number: creditApp.referenceNumber })}</h1>
          <h3 className="text-light">{applicantFullName(creditApp.applicant)}</h3>
        </div>
        <div className="stepStatus-container-horizontal">
          <StatusIcon message={<Trans i18nKey="overview.creditStep" />} />
          <StatusIcon message={t('overview.documentsStep')} />
          <StatusIcon message={<Trans i18nKey="overview.incomeStepDone" />} />
          <StatusIcon message={<Trans i18nKey="overview.loanContract" />} />
          <StatusIcon message={t('overview.rateExperienceStep')} />
        </div>
        <div className="limited-width-container" style={{ maxWidth: '60rem' }}>
          <h2>{t('common.thankYou')}</h2>
          <div className="paragraph">
            <p>{t('overview.applicationCompleted')}</p>
            <p>
              <Trans
                i18nKey="overview.applicationCompletedContactClient"
                values={{ email: creditApp.applicant.email }}
              />
            </p>
            <p>
              <Trans
                i18nKey="overview.applicationCompletedContactUs"
                values={{
                  phoneNumber: PHONE_NUMBER,
                  email: EMAIL,
                  referenceNumber: creditApp.referenceNumber,
                }}
                components={{
                  phone: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap' }} />,
                  email: (
                    <Link
                      to={`mailto:${EMAIL}?subject=${t('common.appNumber', { number: creditApp.referenceNumber })}`}
                      style={{ textWrap: 'nowrap' }}
                    />
                  ),
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default NoMoreActionMessage
