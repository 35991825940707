import { VouchedJob } from '@src/types/vouched/vouched-types'

export enum VouchedErrorType {
  LivenessSkippedError = 'LivenessSkippedError',
}

export const isJobValid = (job: VouchedJob) => {
  return (
    job.result.success &&
    (job.errors.length === 0 ||
      (job.errors.length === 1 && (job.errors[0].type as VouchedErrorType) === VouchedErrorType.LivenessSkippedError))
  )
}
