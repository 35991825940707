import { CustomerFeedback } from '@src/api/feedback-api'
import { EConsignoSignatureStatus, EContractStatus } from '@src/types'
import {
  hasApplicantCompletedFlinks,
  hasApplicantSubmittedAllRequiredIncomeDocuments,
  hasApplicantSubmittedFlinks,
} from '@src/data/creditapp-selectors'
import { FilteredCreditApplication, RequiredDocumentWithStatus } from '@src/api/types/FilteredCreditApplication'
import { CreditStepStatus } from './OverviewPage/components/CreditStep'

const contractIsNotReadyStatusSet = new Set([EContractStatus.NotReady, EContractStatus.Sending])

const contractIsReceivedStatusSet = new Set([
  EContractStatus.UnderReview,
  EContractStatus.Completed,
  EContractStatus.Declined,
])

export function contractIsNotReady(creditApp: FilteredCreditApplication) {
  return contractIsNotReadyStatusSet.has(creditApp.contract.status)
}

export function contractHasSigner(creditApp: FilteredCreditApplication | null) {
  return creditApp?.signers.some((x) => x.isApplicant)
}

export function contractIsReceived(creditApp: FilteredCreditApplication | null) {
  return creditApp && (contractIsReceivedStatusSet.has(creditApp.contract.status) || contractHasSigner(creditApp))
}

export function isReadyForCustomerFeedback(
  creditApp: FilteredCreditApplication | null,
  requiredDocuments: RequiredDocumentWithStatus[],
): boolean {
  if (!creditApp) return false
  return (
    creditApp.allIncomesConfirmed ||
    hasApplicantCompletedFlinks(creditApp) ||
    hasApplicantSubmittedFlinks(creditApp) ||
    hasApplicantSubmittedAllRequiredIncomeDocuments(requiredDocuments)
  )
}

export function getFeedbackStepStatus(
  creditApp: FilteredCreditApplication | null,
  requiredDocuments: RequiredDocumentWithStatus[],
  feedback: CustomerFeedback | null,
) {
  if (!isReadyForCustomerFeedback(creditApp, requiredDocuments)) return CreditStepStatus.NotReady
  if (!feedback) return CreditStepStatus.RequiresAttention
  return CreditStepStatus.Completed
}

export function isEverythingDoneForClient(
  creditApp: FilteredCreditApplication,
  feedback: CustomerFeedback | null,
): boolean {
  return (
    Boolean(feedback) && // feedback step done
    (creditApp.contract.status === EContractStatus.Completed ||
      creditApp.signers.some((x) => x.status === EConsignoSignatureStatus.Completed)) // contract signed
  )
}
