import { BrowserRouter as Router } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { AppContext, useAppStoreProvider } from '@src/data/AppContext'
import { OriginationTracking } from '@src/types/origination'
import { frCA, enCA } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns'
import { ReactNode } from 'react'
import TopLevelRoutes from './Routes'
import Header from './components/Header'
import ScrollToTop from './components/ScrollToTop'
import Footer from './components/Footer'

const App = (): ReactNode => {
  const { i18n } = useTranslation()

  let origination: OriginationTracking | null = null
  const searchParams = new URLSearchParams(window.location.search)
  const sourceId = searchParams.get('sid')
  const campaignId = searchParams.get('cid')
  const mediumId = searchParams.get('mid')
  if (sourceId && campaignId && mediumId) {
    origination = { sourceId, campaignId, mediumId }
  }

  if (i18n.resolvedLanguage === 'fr') setDefaultOptions({ locale: frCA })
  else if (i18n.resolvedLanguage === 'en') setDefaultOptions({ locale: enCA })

  const appStore = useAppStoreProvider(origination)

  if (
    window.location.hostname === 'qualify.ifinancecanada.com' &&
    new Date() < new Date('2025-02-18T04:30:00.000-05:00')
  )
    return (
      <main
        className="form-section"
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        <div className="container" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h1 style={{ marginBottom: '4rem', textAlign: 'center' }}>
            <Trans i18nKey="common.comingSoon" />
          </h1>
          <div className="large-icon">
            <i className="fa-light fa-person-digging" />
          </div>
        </div>
      </main>
    )

  return (
    <AppContext.Provider value={appStore}>
      <Router>
        <ScrollToTop />
        <Header language={i18n.language} />
        <TopLevelRoutes />
        <Footer />
      </Router>
    </AppContext.Provider>
  )
}

export default App
