import SectionTitle from '@src/components/PageSectionTitle'
import ReviewItems from '@src/components/ReviewItems'
import useFaqLink from '@src/hooks/useFaqLink'
import { FormatCurrencyNoDecimals } from '@src/services/Formatter'
import { MINIMUM_ANNUAL_INCOME } from '@src/types'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

const Eligibility = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const faqLink = useFaqLink()
  const [preconditionSatisfied, setPreconditionSatisfied] = useState(true)
  const [error, setError] = useState(false)

  const handleOnSelect = (satisfied: boolean) => {
    setError(false)
    setPreconditionSatisfied(satisfied)
  }

  const handleContinue = () => {
    navigate(`/apply/loan-amount`, { replace: true })
  }

  return (
    <main className="step-0">
      <div className="strong" style={{ marginBottom: '2rem', fontSize: '0.9em' }}>
        {t('eligibility.preApproved')}
      </div>
      <SectionTitle title={t('welcome.applyInMinutes')} />
      <h1>{t('eligibility.checkList')}</h1>
      <div className="paragraph" style={{ marginTop: '1rem', maxWidth: '100%' }}>
        <p>{t('eligibility.youMust')}</p>
      </div>
      <div className="review-wrap" style={{ margin: 0, padding: 0, display: 'unset' }}>
        <ReviewItems label={t('eligibility.yearOld')} icon="address-card" />
        <ReviewItems
          label={
            <Trans
              i18nKey="eligibility.annualIncome"
              values={{ amount: FormatCurrencyNoDecimals(MINIMUM_ANNUAL_INCOME) }}
            />
          }
          icon="money-check-dollar-pen"
        />
        <ReviewItems label={t('eligibility.validBankAccount')} icon="building-columns" />
      </div>

      <div className={`control-group  ${error && 'error'}`} style={{ marginTop: '1rem' }}>
        <label htmlFor="radiobuttons" style={{ display: 'block' }}>
          {t('eligibility.aboveConditions')}
        </label>
        <div className="radiobuttons-wrap" style={{ display: 'inline-flex' }}>
          <div className="radio-wrap">
            <input
              type="radio"
              required
              id="no"
              checked={preconditionSatisfied === false}
              onChange={() => handleOnSelect(false)}
            />
            <label htmlFor="no">{t('common.no')}</label>
          </div>
          <div className="radio-wrap">
            <input type="radio" id="yes" checked={preconditionSatisfied} onChange={() => handleOnSelect(true)} />
            <label htmlFor="yes">{t('common.yes')}</label>
          </div>
        </div>
      </div>

      {!preconditionSatisfied && (
        <>
          <i
            className="fa-duotone fa-circle-exclamation "
            style={{
              fontSize: 'xxx-large',
              color: '#e97472',
            }}
          />
          <h4
            className="subtitle"
            id="notEligible"
            style={{
              color: '#142743',
              fontWeight: 'bold',
              background: 'none',
              border: '1px solid',
              whiteSpace: 'normal',
            }}
          >
            {t('eligibility.notEligible')}
          </h4>
        </>
      )}

      {preconditionSatisfied && (
        <button id="btnBegin" type="button" onClick={handleContinue} className="btn btn-blue">
          {t('common.continue')}
          <i className="fa-regular fa-arrow-right" />
        </button>
      )}
      <p style={{ fontSize: '1.5rem', marginTop: '1rem' }}>
        <Trans
          i18nKey="welcome.question"
          components={{
            site_anchor: <Link to={faqLink} target="_blank" />,
          }}
        />
      </p>
    </main>
  )
}

export default Eligibility
