import { Trans, useTranslation } from 'react-i18next'
import { EContractStatus, EWorksheetStatus, PHONE_NUMBER, PHONE_NUMBER_LINK } from '@src/types'
import { ReactNode, useMemo } from 'react'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import useRequiredDocumentsWithStatus from '@src/data/creditapp-hooks'
import { applicantFullName, areAllDocumentsReadyForCVT, hasMissingDocuments } from '@src/data/creditapp-selectors'
import { useIsReportExpired } from '@src/containers/Worsheet/hooks'
import { Link } from 'react-router-dom'
import CreditStep, { CreditStepStatus } from './CreditStep'
import { contractIsReceived } from '../../hooks'

type Props = {
  creditApp: FilteredCreditApplication
}

const WorksheetCreditStep = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const reportExpired = useIsReportExpired(creditApp.hardHitReportReceivedOn)
  const requiredDocumentsWithStatus = useRequiredDocumentsWithStatus(creditApp.requiredDocuments, creditApp.documents)

  const { status, content, link } = useMemo<{ status: CreditStepStatus; content?: ReactNode; link?: string }>(() => {
    if (hasMissingDocuments(creditApp)) return { status: CreditStepStatus.NotReady }

    if (reportExpired) {
      return {
        status: CreditStepStatus.RequiresAttention,
        link: creditApp.worksheet?.status === EWorksheetStatus.Draft ? 'worksheet' : 'contract',
        content: (
          <Trans
            i18nKey="overview.contract90Days"
            values={{
              phoneNumber: PHONE_NUMBER,
            }}
            components={{
              a: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap', textDecoration: 'underline' }} />,
            }}
          />
        ),
      }
    }

    if (
      !creditApp.allIncomesConfirmed ||
      !creditApp.allTaskCompletedForCVT ||
      !areAllDocumentsReadyForCVT(requiredDocumentsWithStatus)
    )
      return { status: CreditStepStatus.Await, content: t('overview.worksheetContent') }

    if (contractIsReceived(creditApp)) {
      if (creditApp.contract.status === EContractStatus.Declined)
        return { status: CreditStepStatus.Await, content: t('contract.refused') }
      return { status: CreditStepStatus.Completed, content: `${t('contract.received')} ${t('contract.review')}` }
    }

    return {
      status: CreditStepStatus.RequiresAttention,
      content: t('overview.worksheetContent'),
      link: creditApp.worksheet?.status === EWorksheetStatus.Draft ? 'worksheet' : 'contract',
    }
  }, [creditApp, reportExpired, requiredDocumentsWithStatus, t])

  return (
    <CreditStep
      status={status}
      linkToDetail={link ?? ''}
      title={t('worksheet.title', { name: applicantFullName(creditApp.applicant) })}
      content={content ?? ''}
      hideLinkToDetail={status !== CreditStepStatus.RequiresAttention}
    />
  )
}

export default WorksheetCreditStep
