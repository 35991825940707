type ReviewItemsProps = {
  label: React.ReactNode
  icon: string
}

const ReviewItems = ({ label, icon }: ReviewItemsProps) => {
  return (
    <div className="review-item">
      <div className="box">
        <i className={`fa-light fa-${icon}`} />
        <span>{label}</span>
      </div>
    </div>
  )
}

export default ReviewItems
