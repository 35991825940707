import { useEffect } from 'react'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { Trans, useTranslation } from 'react-i18next'
import { ETrackedEvents, trackEvent } from '@src/services/trackingEvents'
import SectionTitle from '@src/components/PageSectionTitle'
import { PHONE_NUMBER, PHONE_NUMBER_LINK } from '@src/types'
import { Link } from 'react-router-dom'

type Props = {
  creditApp: FilteredCreditApplication
}

const DeclinedDecisionForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()

  useEffect(() => {
    trackEvent(ETrackedEvents.QualifDeclined, creditApp)
  }, [creditApp])

  return (
    <main className="general-message" style={{ textAlign: 'center' }}>
      <SectionTitle title={t('common.financeApplication')} />
      <p>
        <Trans
          i18nKey="finalDecision.refused"
          values={{
            phoneNumber: PHONE_NUMBER,
          }}
          components={{
            a: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap', textDecoration: 'underline' }} />,
          }}
        />
      </p>
    </main>
  )
}

export default DeclinedDecisionForm
