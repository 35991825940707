import { Trans, useTranslation } from 'react-i18next'
import { FormatCurrency } from '@src/services/Formatter'
import { InputText } from '@src/components'
import { useController, useFormContext } from 'react-hook-form'
import { StepStateUpdater } from '@src/components/Stepper/StepperStep'
import { useEffect } from 'react'
import { WorksheetForm } from '@src/containers/Worsheet/schema-hooks'

type Props = {
  maxAmountFinanced: number
  setStepState: StepStateUpdater
}

const FinalAmountStep = ({ maxAmountFinanced, setStepState }: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext<WorksheetForm>()
  const {
    field,
    fieldState: { error, invalid },
  } = useController({
    name: 'amountRequested',
    control,
  })

  useEffect(() => setStepState({ invalid }), [invalid, setStepState])

  return (
    <>
      <h3 style={{ marginBottom: '16px' }}>
        <i className="fa-solid fa-party-horn" />{' '}
        <Trans
          i18nKey="worksheet.finalAmount.title"
          values={{
            maxLoanAmount: FormatCurrency(maxAmountFinanced),
          }}
        />
      </h3>
      <h4 style={{ marginBottom: '16px' }}>{t('worksheet.finalAmount.confirmation')}</h4>
      <InputText
        error={error}
        label={t('worksheet.finalAmount.label')}
        id="amountRequested"
        type="number"
        inputMode="decimal"
        {...field}
      />
    </>
  )
}

export default FinalAmountStep
