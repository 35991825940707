import { useGetCreditApplicationById } from '@src/api/credit-api'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { ErrorPage, Loader } from '@src/components'
import SectionTitle from '@src/components/PageSectionTitle'
import { EMAIL, PHONE_NUMBER, PHONE_NUMBER_LINK } from '@src/types'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

type Props = {
  creditApp: FilteredCreditApplication
}

const FailedIdentification = ({ creditApp }: Props) => {
  const { t } = useTranslation()

  return (
    <main className="step-0">
      <SectionTitle title={t('identification.failed')} />
      <h1>{t('identification.explanation')}</h1>
      <Link className="btn btn-blue primary-action-btn" to={`/creditApplication/${creditApp.id}/vouched`} replace>
        <span>{t('identification.retryVerification')}</span>
        <i className="fa-regular fa-paper-plane-top" />
      </Link>
      <h4 style={{ marginTop: '5rem' }}>{t('identification.needHelp')}</h4>
      <p>
        <Trans
          i18nKey="common.contactUs"
          values={{
            phoneNumber: PHONE_NUMBER,
            email: EMAIL,
          }}
          components={{
            phone: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap' }} />,
            email: (
              <Link
                to={`mailto:${EMAIL}?subject=${t('identification.needHelpSubject', { applicationNumber: creditApp.referenceNumber })}&body=${t('identification.needHelpBody', { applicationNumber: creditApp.referenceNumber })}`}
                style={{ textWrap: 'nowrap' }}
              />
            ),
          }}
        />
      </p>
    </main>
  )
}

const FailedIdentificationPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [creditApp, loading] = useGetCreditApplicationById(id!)

  if (loading) return <Loader />
  if (!creditApp) return <ErrorPage />
  if (creditApp.vouchedFailedCount > 1)
    return (
      <ErrorPage
        title={t('identification.failedNoRetryTitle')}
        description={
          <>
            {t('identification.failedNoRetryDescription')}
            <h4 style={{ marginTop: '5rem' }}>{t('identification.needHelp')}</h4>
            <Trans
              i18nKey="common.contactUs"
              values={{
                phoneNumber: PHONE_NUMBER,
                email: EMAIL,
              }}
              components={{
                phone: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap' }} />,
                email: (
                  <Link
                    to={`mailto:${EMAIL}?subject=${t('identification.needHelpSubject', { applicationNumber: creditApp.referenceNumber })}&body=${t('identification.needHelpBody', { applicationNumber: creditApp.referenceNumber })}`}
                    style={{ textWrap: 'nowrap' }}
                  />
                ),
              }}
            />
          </>
        }
        disableBackButton
      />
    )

  return <FailedIdentification creditApp={creditApp} />
}

export default FailedIdentificationPage
